import { Link, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Authentication";
import IconUser from "../../assets/sidebar/user.png";
import IconDashboard from "../../assets/sidebar/dashboard-1.png";
import IconChat from "../../assets/sidebar/chat.png";
import IconDoors from "../../assets/icons/icon-site.svg";
import IconFinder from "../../assets/icons/icon-finder.svg";
import IconMedia from "../../assets/icons/gallery.svg";
import IconQR from "../../assets/icons/icon-qr.svg";
import IconScn from "../../assets/icons/icon-scan.svg";
import IconSite from "../../assets/sidebar/www.png";
import IconSiteQR from "../../assets/icons/icon-site-qr.svg";
import IconLogout from "../../assets/icons/logout.svg";
import IconLockersBank from "../../assets/sidebar/locker-bankk.png";
import Injection from "../../assets/sidebar/product-img.png";
import logo_site from "../../assets/logo_black.png";

import { Auth } from "aws-amplify";
import { NavLink } from "react-router-dom";
import "./partials.css";
const Sidebar = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user);
  // const imageUrl = "/assets/images/logo/logo-fast-lobby-blue.png";
  // const imageUrl1 = "/assets/images/logo/icon-fast-lobby.png";
  const handleUserLogout = async (e) => {
    try {
      // Sign out from AWS Cognito and wait until it's done
      await Auth.signOut();

      // After successful sign out, remove the token from localStorage
      localStorage.removeItem("token");

      // Dispatch the logout action to update the app's state
      dispatch(logout());

      // Navigate to the homepage after all steps are complete
      navigate("/");
    } catch (error) {
      // Handle any errors during the sign-out process
      console.error("Error signing out:", error);
    }
  };

  useEffect(() => {
    // Clean up the event listener when the component unmounts
  }, []);
  return (
    <div>
      <section className="sidebar_wrap">
        <div className="logo-wrap text-center">
          <img
            src={logo_site}
            alt="logo"
            className="logo-sidebar-wrap"
          
            width={120}
          />
        </div>
        <ul>
          <li>
            <NavLink to="/dashboard">
              <img src={IconDashboard} alt="icon" />
              <span>Dashboard</span>
            </NavLink>
          </li>
          {((isAuthenticated &&
            isAuthenticated.role.toLowerCase() === "site admin") ||
            isAuthenticated.role.toLowerCase() === "super admin" ||
            isAuthenticated.role.toLowerCase() === "company admin") && (
            <li>
              <NavLink to="/user/list">
                <img src={IconUser} alt="icon" />
                <span>Users</span>
              </NavLink>
            </li>
          )}
          <li>
            <NavLink to="/chats">
              <img src={IconChat} alt="icon" />
              <span>Chats</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/cabnets">
              <img src={IconChat} alt="icon" />
              <span>Cabnets</span>
            </NavLink>
          </li>

          {((isAuthenticated &&
            isAuthenticated.role.toLowerCase() === "super admin") ||
            isAuthenticated.role.toLowerCase() === "company admin") && (
            <>
              <li>
                <NavLink to="/site/list">
                  <img src={IconSite} alt="icon" />
                  <span>Sites</span>
                </NavLink>
              </li>
            </>
          )}
          {isAuthenticated &&
            isAuthenticated.role.toLowerCase() === "super admin" && (
              <>
                <li>
                  <NavLink to="/company/list">
                    <img src={IconDoors} alt="icon" />
                    <span>Company</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/qr/type/list">
                    <img src={IconQR} alt="icon" />
                    <span>QR Code Types</span>
                  </NavLink>
                </li>
              </>
            )}

          {/* <li>
            <NavLink to="/lockers">
              <img src={IconLockers} alt="icon" />
              <span>Lockers</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/logs">
              <img src={IconLockers} alt="icon" />
              <span>Lockers Logs</span>
            </NavLink>
          </li> */}

          {((isAuthenticated &&
            isAuthenticated.role.toLowerCase() === "site admin") ||
            isAuthenticated.role.toLowerCase() === "company admin" ||
            isAuthenticated.role.toLowerCase() === "super admin") && (
            <>
              <li>
                <NavLink to="/locker/bank">
                  <img src={IconLockersBank} alt="icon" />
                  <span>Locker Bank</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/product/list">
                  <img src={Injection} alt="icon" />
                  <span>Products</span>
                </NavLink>
              </li>
            </>
          )}
          {isAuthenticated &&
            isAuthenticated.role.toLowerCase() !== "site user" &&
            isAuthenticated.role.toLowerCase() !== "company admin" && (
              <>
                {/* <li>
                <NavLink to="/requested/product/list">
                  <img src={Injection} alt="icon" />
                  <span>Requested Products</span>
                </NavLink>
              </li> */}

                {/* <li>
                  <NavLink to="/shipping/label/list">
                    <img src={IconShippingLabel} alt="icon" />
                    <span>Shipping Labels</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/live/shipping/tracking">
                    <img src={IconShippingTrack} alt="icon" />
                    <span>Shipping Tracking</span>
                  </NavLink>
                </li> */}
              </>
            )}
          {isAuthenticated && isAuthenticated.role.toLowerCase() === "user" && (
            <>
              <li>
                <NavLink to="/pricing">
                  <img src={IconDoors} alt="icon" />
                  <span>pricing</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/booking/add">
                  <img src={IconScn} alt="icon" />
                  <span>Book A Locker</span>
                </NavLink>
              </li>
            </>
          )}
          {/* 
          <li>
            <NavLink to="/booking/list" exact>
              <img src={IconBookingList} alt="icon" />
              <span>
                {isAuthenticated.role.toLowerCase() === "user" ? "Your" : ""}{" "}
                booking list
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/payment/list" exact>
              <img src={IconPayement} alt="icon" />
              <span>
                {isAuthenticated.role.toLowerCase() === "user" ? "Your" : ""}{" "}
                payment list
              </span>
            </NavLink>
          </li> */}

          {isAuthenticated &&
            isAuthenticated.role.toLowerCase() === "site admin" && (
              <>
                <li>
                  <NavLink to="/site/qr/codes/list">
                    <img src={IconSiteQR} alt="icon" />
                    <span>Site QR Codes</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/label">
                    <img src={IconFinder} alt="icon" />
                    <span>Label OCR</span>
                  </NavLink>
                </li>
              </>
            )}
          {((isAuthenticated &&
            isAuthenticated.role.toLowerCase() === "site admin") ||
            isAuthenticated.role.toLowerCase() === "company admin") && (
            <>
              <li>
                <NavLink to="/media/contents" exact>
                  <img src={IconMedia} alt="icon" />
                  <span>Media Content</span>
                </NavLink>
              </li>
            </>
          )}
          <li>
            <Link
              target="_blank"
              to={"https://dashboard.invidtech.com/login"}
              exact
            >
              <img src={IconUser} alt="icon" />
              <span>Content</span>
            </Link>
          </li>
          <li>
            <Link onClick={handleUserLogout} exact>
              <img src={IconLogout} alt="icon" />
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Sidebar;
