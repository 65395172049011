import React, { useEffect, useState } from "react";
import download from "downloadjs";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import HashLoader from "react-spinners/ClipLoader";
import "sweetalert2/dist/sweetalert2.min.css";
import CrossIcon from "../../../assets/icons/cross_icon.svg";
import Mask from "../../../assets/new-design/Mask-group.png";
import PlusButton from "../../../assets/new-design/plus-btn.svg";
import MinusButton from "../../../assets/new-design/minus-btn.svg";

import Modal from "react-modal";
import io from "socket.io-client";
import { notification } from "antd";
const url = process.env.REACT_APP_APP_BACK_URL;

const socket = io.connect(url);

const Dashboard = () => {
  const isAuthenticated = useSelector((state) => state.user);

  const role = isAuthenticated.role;
  const site = isAuthenticated.site;
  const id = isAuthenticated._id;
  const [availableProductList, setAvailableProduct] = useState([]);
  const [availableRequestProductList, setAvailableRequestProduct] = useState(
    []
  );
  const [getSite, setSite] = useState("");
  const [onOrderProduct, setOnOrderProduct] = useState([]);
  const [request, setRequest] = useState();
  const [requestedProduct, setRequestedProduct] = useState([]);
  const [getSelectedRquestedProduct, setSelectedRquestedProduct] = useState([]);
  const [getLockerHistory, setLockerHistory] = useState([]);
  const [getSiteList, setSiteList] = useState([]);
  const [getUsersRequests, setUsersRequests] = useState([]);
  const [availableNotFound, setAvailableNotFound] = useState(false);
  const [requestedNotFound, setRequestedNotFound] = useState(false);
  const [onOrderdNotFound, setOnorderdNotFound] = useState(false);
  const [requestedSelectedNotFound, setRequestedSelectedNotFound] =
    useState(false);
  const [usersRequestsNotFound, setUsersRequestsNotFound] = useState(false);
  const [lockerHistoryNotFound, setLockerHistoryNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isDisplay1, setIsDisplay1] = useState(false);
  const [lockerCount, setLockerCount] = useState([]);
  const [lockerSizes, setLockerSizes] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [bookingNotFound, setBookingNotFound] = useState(false);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [bookingDisplay, setBookingDisplay] = useState(false);
  const [credentials, setCredentials] = useState({ name: "" });
  const [credentials1, setCredentials1] = useState({ name: "" });
  const [seletedProduct, setSeletedProduct] = useState({ product: "" });
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [totalProduct, setTotalProduct] = useState(0);
  const [productQuantities, setProductQuantities] = useState({});
  const [totalProduct1, setTotalProduct1] = useState(0);
  const [productQuantities1, setProductQuantities1] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isPrice, setIsPrice] = useState({});
  const [productID, setProductID] = useState({});
  const [file, setFile] = useState(null);
  const [position, setPosition] = useState(0);
  const [sendCode, setSendCode] = useState(false);
  const [sendCode1, setSendCode1] = useState(false);
  const [sendCode2, setSendCode2] = useState(false);
  const [credentialsModal, setCredentialsModal] = useState({
    name: "",
    quantity: "",
  });
  const [siteId, setSiteId] = useState({});

  const tableData = [
    {
      id: 1,
      name: "Imran has requested to access the Invid site",
      // site: "Invid",
      pin: 1234,
      date: "Mar 28, 2024 01:04pm",
      status: "Pending",
    },
    {
      id: 2,
      name: "Hamza has requested to access the locker# 4",
      // locker: "Locker 4",
      pin: 1234,
      date: "Mar 28, 2024 01:04pm",
      status: "Pending",
    },
    {
      id: 3,
      name: "Shoaib has requested to access the Invid site",
      // site: "Invid",
      pin: 1234,
      date: "Mar 28, 2024 01:04pm",
      status: "Pending",
    },
    {
      id: 4,
      name: "Haider has requested to access the locker# 12",
      // locker: "Locker 12",
      pin: 1234,
      date: "Mar 28, 2024 01:04pm",
      status: "Pending",
    },
  ];

  useEffect(() => {
    // socket.emit("user_login", isAuthenticated._id);
    socket.on("requestedProduct", (data) => {
      onOrderProductList(data.site);
      requestedProductData(data.site);
    });
    socket.on("requestedProductCompany", (data) => {
      handleSite(data.site);
    });
  }, [isAuthenticated]);
  useEffect(() => {
    socket.on("updateProductquantity", (data) => {
      if (role.toLowerCase() === "company admin") {
        siteList();
      } else {
        availableProduct(credentials, site);
      }
    });
    socket.on("updateUserAccessData", (data) => {
      if (role.toLowerCase() === "company admin") {
        siteList();
      } else {
        usersRequestsData(site);
      }
    });

    if (role.toLowerCase() === "company admin") {
      siteList();
    } else {
      onOrderProductList(site);
      requestedProductData(site);
      usersRequestsData(site);
      lockerHistory(site);
      availableProduct(credentials, site);

      availableRequestedProduct(credentials1, site);
    }
  }, [site, role]);

  // handle site
  const handleSite = async (site) => {
    setSite(site);
    setIsActive(site);
    onOrderProductList(site);
    requestedProductData(site);
    usersRequestsData(site);
    lockerHistory(site);
    availableProduct(credentials, site);
  };
  //List of all site
  const siteList = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/sites/companySiteList?company=${
          isAuthenticated.company_id ? isAuthenticated.company_id : ""
        }`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.site_data.length > 0) {
        handleSite(json.site_data[0]._id);
        setSiteList(json.site_data);
      } else {
        setSiteList(json.site_data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const availableProduct = async (fetchCredentials, site) => {
    try {
      setSiteId(site);
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/inventory/getAvailableInventoryList?site=${site}&role=${role}&name=${fetchCredentials.name}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.inventory.length > 0) {
        setAvailableNotFound(false);
        setAvailableProduct(json.inventory);
      } else {
        setAvailableProduct([]);
        setAvailableNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const availableRequestedProduct = async (fetchCredentials, site) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/inventory/getAvailableInventoryList?site=${site}&role=${role}&name=${fetchCredentials.name}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.inventory.length > 0) {
        setRequestedNotFound(false);
        setAvailableRequestProduct(json.inventory);
      } else {
        setAvailableRequestProduct([]);
        setRequestedNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onOrderProductList = async (site) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading2(true);
      const response = await fetch(
        `${url}/api/inventory/onOrderProductList?site=${site}&role=${role}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.onOrderData.length > 0) {
        setIsLoading2(false);
        setOnorderdNotFound(false);
        setIsDisplay(true);
        setOnOrderProduct(json.onOrderData);
      } else {
        setIsDisplay(false);
        setIsLoading2(false);
        setOnOrderProduct([]);
        setOnorderdNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const requestedProductData = async (site) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/inventory/requestedProductData?site=${site}&role=${role}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.inventory.length > 0) {
        setRequestedSelectedNotFound(false);
        setRequestedProduct(json.inventory);
        getRquestedProduct(json.inventory[0]._id);
      } else {
        setRequest("");
        setSelectedRquestedProduct([]);
        setRequestedSelectedNotFound(true);
        setRequestedProduct([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // user requests
  const usersRequestsData = async (site) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/sites/get/user/request/${site}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.data.length > 0) {
        setUsersRequests(json.data);
      } else {
        setUsersRequests([]);
        setUsersRequestsNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // locker history

  const lockerHistory = async (site) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading3(true);
      const response = await fetch(
        `${url}/api/inventory/lockerHistory?site=${site}&role=${role}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const json = await response.json();

      setIsLoading3(false);
      if (json.lockerHistory.length > 0) {
        setLockerHistoryNotFound(false);
        setIsDisplay1(true);
        setLockerHistory(json.lockerHistory);
      } else {
        setIsDisplay1(false);
        setLockerHistoryNotFound(true);
        setLockerHistory([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // handle requested product
  const handleRquestedProduct = async (e) => {
    getRquestedProduct(e.target.value);
  };
  // get requested product list
  const getRquestedProduct = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/inventory/selectedRequest/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.inventory.length > 0) {
        setRequest(id);
        setRequestedSelectedNotFound(false);
        setSelectedRquestedProduct(json.inventory);
      } else {
        setRequest("");
        setSelectedRquestedProduct(json.inventory);
        setRequestedSelectedNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleIncrement = (id, quantity) => {
    const updatedQuantities = { ...productQuantities };
    updatedQuantities[id] = (updatedQuantities[id] || 0) + 1;
    if (updatedQuantities[id] > quantity) {
      notification.error({
        message: "Failed",
        description: " Please select only " + quantity + " items",
        duration: 3,
      });
      return; // Exit the function early
    }
    setProductQuantities(updatedQuantities);
    updateTotalProduct(updatedQuantities);
  };

  const handleDecrement = (id) => {
    const updatedQuantities = { ...productQuantities };
    if (updatedQuantities[id] > 0) {
      updatedQuantities[id] = (updatedQuantities[id] || 0) - 1;
      setProductQuantities(updatedQuantities);
      updateTotalProduct(updatedQuantities);
    } else {
      notification.error({
        message: "Failed",
        description: "Please add product quantity",
        duration: 3,
      });
    }
  };

  const updateTotalProduct = (quantities) => {
    const total = Object.values(quantities).reduce(
      (acc, val) => acc + Math.abs(val),
      0
    );
    setTotalProduct(total);
  };

  const handleIncrement1 = (id) => {
    const updatedQuantities = { ...productQuantities1 };
    updatedQuantities[id] = (updatedQuantities[id] || 0) + 1;
    setProductQuantities1(updatedQuantities);
    updateTotalProduct1(updatedQuantities);
  };

  const handleDecrement1 = (id) => {
    const updatedQuantities = { ...productQuantities1 };
    if (updatedQuantities[id] > 0) {
      updatedQuantities[id] = (updatedQuantities[id] || 0) - 1;
      setProductQuantities1(updatedQuantities);
      updateTotalProduct1(updatedQuantities);
    }
  };
  const handleIncrement2 = (id, quantity) => {
    const updatedQuantities = { ...productQuantities1 };

    updatedQuantities[id] =
      (updatedQuantities[id] || parseInt(quantity, 10)) + 1;
    setProductQuantities1(updatedQuantities);
    updateTotalProduct1(updatedQuantities);
  };

  const handleDecrement2 = (id) => {
    const updatedQuantities = { ...productQuantities1 };
    if (updatedQuantities[id] > 0) {
      updatedQuantities[id] = (updatedQuantities[id] || 0) - 1;
      setProductQuantities1(updatedQuantities);
      updateTotalProduct1(updatedQuantities);
    }
  };
  const updateTotalProduct1 = (quantities) => {
    const total = Object.values(quantities).reduce(
      (acc, val) => acc + Math.abs(val),
      0
    );
    setTotalProduct1(total);
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      const response = await fetch(
        `${url}/api/inventory/updateAvailableQuantity`,
        {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            productQuantities,
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        setIsLoading(false);
        setProductQuantities("");
        setTotalProduct("");
        availableProduct(credentials, getSite ? getSite : site);
        notification.success({
          message: "Success",
          description: data.message,
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: data.error,
          duration: 3,
        });
      }
      // Reset form or handle success as needed
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const handleRequestedProduct = async (e) => {
    try {
      e.preventDefault();
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading1(true);
      const response = await fetch(
        `${url}/api/inventory/requestProductQuantity`,
        {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            productQuantities1,
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        const admin = data.admin;
        const companyAdmin = data.company_admin;
        socket.emit("sendAdmin", { admin, site });
        socket.emit("sendCompanyAdmin", { companyAdmin, site });
        setIsLoading1(false);
        setProductQuantities1("");
        setTotalProduct1("");
        onOrderProductList();
        notification.success({
          message: "Success",
          description: data.message,
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: data.error,
          duration: 3,
        });
      }
      // Reset form or handle success as needed
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  // order requested product
  const orderRquestedProduct = async (id, quantity) => {
    const updatedQuantities = { ...productQuantities1 };
    const quantities = updatedQuantities[id] ? updatedQuantities[id] : quantity;
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/inventory/orderRequestProductQuantity`,
        {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            id: id,
            quantity: quantities,
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        requestedProductData(site);
        notification.success({
          message: "Success",
          description: data.message,
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: data.error,
          duration: 3,
        });
      }
      // Reset form or handle success as needed
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const HandleOpenLocker = async (id) => {
    let state = "2";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    // setIsLockerOpen(true);
    const response = await fetch(`${url}/api/sites/relay/state/update`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        id: id,
        state: state,
      }),
    });
    // setIsLockerOpen(false);
    const json = await response.json();

    if (json.success) {
      notification.success({
        message: "Success",
        description: json.message,
        duration: 3,
      });
    } else {
      notification.error({
        message: "Failed",
        description: json.error,
        duration: 3,
      });
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const iocnStyle1 = {
    display: isLoading1 ? "inline-block" : "none",
  };
  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  };

  const handleInput = (e) => {
    // Use the callback function to ensure that the state is updated before calling fetchData
    setCredentials((prevCredentials) => {
      const updatedCredentials = {
        ...prevCredentials,
        [e.target.name]: e.target.value,
      };
      availableProduct(updatedCredentials, siteId);
      return updatedCredentials;
    });
  };
  const handleInput1 = (e) => {
    // Use the callback function to ensure that the state is updated before calling fetchData
    setCredentials1((prevCredentials) => {
      const updatedCredentials = {
        ...prevCredentials,
        [e.target.name]: e.target.value,
      };
      availableRequestedProduct(updatedCredentials, site);
      return updatedCredentials;
    });
  };

  const handleInput3 = (e) => {
    setCredentialsModal({
      ...credentialsModal,
      [e.target.name]: e.target.value,
    });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    formData.append("name", credentialsModal.name);
    formData.append("quantity", credentialsModal.quantity);
    formData.append("price", credentialsModal.price);
    formData.append("site", siteId);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("auth-token", localStorage.getItem("token"));
    setIsLoading(true);
    const response = await fetch(`${url}/api/inventory/createInventory`, {
      method: "POST",
      body: formData,
    });

    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      setCredentialsModal({
        name: "",
        quantity: "",
        price: "",
      });
      notification.success({
        message: "Success",
        description: "Product Created Successfully",
        duration: 3,
      });
      availableProduct(credentials, siteId);
      setIsModalOpen(false);
    } else {
      notification.error({
        message: "Failed",
        description: json.error,
        duration: 3,
      });
    }
  };

  const handleSubmitPrice = async (e) => {
    try {
      e.preventDefault();
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      const response = await fetch(`${url}/api/products/updateProductPrice`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          id: productID,
          price: isPrice,
        }),
      });
      const data = await response.json();
      if (data.success) {
        setIsLoading(false);
        setIsPrice(isPrice);
        notification.success({
          message: "Success",
          description: data.message,
          duration: 3,
        });
        availableProduct(credentials, siteId);
        setIsModalOpen2(false);
      } else {
        notification.error({
          message: "Failed",
          description: data.error,
          duration: 3,
        });
        setIsModalOpen2(false);
      }
      // Reset form or handle success as needed
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const handlePrice = async (id, price) => {
    setProductID(id);
    setIsPrice(price);
    setIsModalOpen2(true);
  };
  const handleChangeInput = (e) => {
    setIsPrice(e.target.value);
  };
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };
  const fetchDataAuth = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      const response = await fetch(`${url}/api/auth/generate/excel`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });

      if (response.ok) {
        const blob = await response.blob();
        download(blob, "LockerHistory.xlsx");
        setIsLoading(false);
        notification.success({
          message: "Success",
          description: "Successfully Generate Excel File",
          duration: 3,
        });
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Failed",
        description: "Error in Generating Excel File",
        duration: 3,
      });
    }
  };

  const handleCode = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/sites/approved/request/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(true);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.success) {
        setIsLoading(false);
        const receiverId = json.userId;
        socket.emit("sendMessageNotification", { receiverId });
        notification.success({
          message: "Success",
          description: "OTP send Successfully",
          duration: 3,
        });
        if (role.toLowerCase() === "company admin") {
          siteList();
        } else {
          usersRequestsData(site);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.body_wrap}>
      <div className="dashboard-content m-2">
        <div className="dashboard-content-wrap">
          {role.toLowerCase() === "company admin" &&
            getSiteList.length > 0 &&
            getSiteList.map((item, key) => (
              <>
                <div
                  className={`text-wrap site-list-text  text-capitalize ${
                    isActive === item._id ? "active" : ""
                  }`}
                  onClick={() => handleSite(item._id)}
                >
                  {item.name}
                </div>
              </>
            ))}
        </div>
      </div>
      {/* {isAuthenticated.role.toLowerCase() === "site user" && (
        <> */}
      <div className="main-containerbox m-3">
        <div className="data-box">
          <div className="top-heading-data">
            <div className="row">
              <div className="col-md-5">
                <h5 className="mt-2">Available Product</h5>
                <p className="description-text">
                  Select product quantities and click open locker to take out
                </p>
              </div>
              <div className="col-md-2 total-row">
                <p className="total-text text-center mx-2">Total</p>
                <p className="total-counter">
                  {totalProduct ? totalProduct : "0"}
                </p>
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control search-box"
                  id="fuserName"
                  name="name"
                  placeholder="Search Product"
                  value={credentials.name}
                  onChange={handleInput}
                />
              </div>
              <div className="col-md-2">
                <div className="d-flex justify-content-center align-items-center flex-column ">
                  <button
                    onClick={() => setIsModalOpen(true)}
                    disabled={isLoading}
                    className="btn btn-prim"
                  >
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>
                    Add New Product
                  </button>
                  {/* {role.toLowerCase() === "company admin" && (
                    <button
                      onClick={() => setIsModalOpen(true)}
                      disabled={isLoading}
                      className="btn btn-prim"
                    >
                      <i
                        className="fa fa-spinner fa-spin"
                        style={iocnStyle}
                      ></i>
                      Add Product
                    </button>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          {availableProductList.length === 4 && (
            <div className="row text-end ">
              <Link
                to={`/all/available/products/${getSite ? getSite : site}`}
                className="mt-2"
              >
                View All
              </Link>
            </div>
          )}

          <div className="booked-container-details mt-3">
            <div className="d-flex flex-row gap-3 locker-slide">
              {availableProductList.length > 0 &&
                availableProductList.map((item, key) => (
                  <div key={key} className="booked-container">
                    <div className="booked-data">
                      <div className="product-det">
                        <div className="product-images">
                          <img
                            className="d-block"
                            src={item.image ? item.image : Mask}
                            alt="product-image"
                          />
                        </div>
                        <div className="product-count">
                          <p>
                            {item.l_count} in Locker {item.l_no}
                          </p>
                        </div>
                        <div className="product-name">
                          <h5 className="text-capitalize ">
                            {item.name.split(" ").length > 3
                              ? `${item.name.split(" ").slice(0, 3).join(" ")}`
                              : item.name}
                          </h5>
                        </div>
                        <div className="d-flex justify-content-between flex-row gap-2 mt-3">
                          <p
                            style={{
                              fontSize: "12px",
                              fontWeight: "700",
                            }}
                          >
                            ${item.price ? item.price : "0"}
                          </p>
                          <div className="product-cart">
                            <div className="d-flex justify-content-center gap-2">
                              <img
                                src={MinusButton}
                                onClick={() => handleDecrement(item._id)}
                              />
                              <p>
                                {" "}
                                {productQuantities[item._id]
                                  ? productQuantities[item._id]
                                  : "0"}
                              </p>
                              <img
                                src={PlusButton}
                                onClick={() =>
                                  handleIncrement(item._id, item.quantity)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="product-btnn">
                        <div className="row">
                          <div className="col-12">
                            <button
                              onClick={() => HandleOpenLocker(item.locker)}
                              className="btn btn-prim btn-increment"
                            >
                              Open Locker
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {availableNotFound && (
            <div className="row ">
              <div className="col-12">
                <p className="text-center text-danger">No data found.</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {role.toLowerCase() === "site user" && (
        <div className="main-containerbox m-3">
          <div className="data-box">
            <div className="top-heading-data">
              <div className="row">
                <div className="col-md-5">
                  <h5 className="mt-2">Requested Product</h5>
                  <p className="description-text">
                    Select product quantity and click button to request the
                    product
                  </p>
                </div>
                <div className="col-md-2 total-row">
                  <p className="total-text text-center">Total:</p>
                  <p className="total-counter">
                    {totalProduct1 ? totalProduct1 : "0"}
                  </p>
                </div>
                <div className="col-md-3">
                  <input
                    type="text"
                    className="form-control search-box"
                    id="fuserName"
                    name="name"
                    onChange={handleInput1}
                    placeholder="Search Product"
                    value={credentials1.name}
                  />
                </div>
                <div className="col-md-2">
                  <button
                    onClick={handleRequestedProduct}
                    disabled={isLoading1}
                    className="btn btn-prim"
                  >
                    <i className="fa fa-spinner fa-spin" style={iocnStyle1}></i>
                    Request Product
                  </button>
                </div>
              </div>
            </div>
            {availableRequestProductList.length === 4 && (
              <div className="d-flex justify-content-end align-items-center">
                <Link
                  to={`/all/requested/products/${site}`}
                  className="btn btn-prim w-auto"
                >
                  View All
                </Link>
              </div>
            )}
            <div className="booked-container-details mt-3">
              <div className="d-flex flex-row gap-3 locker-slide">
                {availableRequestProductList.length > 0 &&
                  availableRequestProductList.map((item, key) => (
                    <>
                      <div key={key} className="booked-container">
                        <div className="booked-data">
                          <div className="product-images">
                            <img
                              src={item.image ? item.image : Mask}
                              alt="product-image"
                            />
                          </div>
                          <div className="product-count">
                            <p>
                              {item.l_count} in Locker {item.l_no}
                            </p>
                          </div>
                          <div className="product-name">
                            <h5 className="text-capitalize ">
                              {item.name.split(" ").length > 3
                                ? `${item.name
                                    .split(" ")
                                    .slice(0, 3)
                                    .join(" ")}`
                                : item.name}
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between flex-row gap-2 mt-3">
                            <p
                              style={{
                                fontSize: "12px",
                                fontWeight: "700",
                              }}
                            >
                              ${item.price ? item.price : "0"}
                            </p>
                            <div className="product-cart">
                              <div className="d-flex justify-content-center gap-2">
                                <img
                                  src={MinusButton}
                                  onClick={() => handleDecrement1(item._id)}
                                />
                                <p>
                                  {" "}
                                  {productQuantities1[item._id]
                                    ? productQuantities1[item._id]
                                    : "0"}
                                </p>
                                <img
                                  src={PlusButton}
                                  onClick={() => handleIncrement1(item._id)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <button
                                onClick={() => HandleOpenLocker(item.locker)}
                                className="btn btn-prim btn-increment"
                              >
                                Open Locker
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
              {requestedNotFound && (
                <div className="row ">
                  <div className="col-12">
                    <p className="text-center text-danger">No data found.</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {role.toLowerCase() !== "site user" && (
        <div className="main-containerbox m-3">
          <div className="data-box">
            <div className="top-heading-data">
              <div className="row">
                <div className="col-md-5">
                  <h5 className="mt-2">Requested Product</h5>
                  <p className="description-text">
                    Select product quantity and click button to request the
                    product
                  </p>
                </div>
                <div className="col-md-5 total-row">
                  <p className="total-text">Rquested By</p>
                  <div className="col-xl-9 col-lg-9">
                    <div className="inpt-wrap select-box ">
                      <select
                        className="text-capitalize"
                        name="product"
                        onChange={handleRquestedProduct}
                      >
                        {requestedProduct.map((per) => (
                          <option key={per._id} value={per._id}>
                            {per.u_name} (Req-{per.req_no})
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-2">
                  <button
                    onClick={handleRequestedProduct}
                    disabled={isLoading1}
                    className="btn btn-prim"
                  >
                    <i className="fa fa-spinner fa-spin" style={iocnStyle1}></i>
                    Request Product
                  </button>
                </div>
              </div>
            </div>
            {getSelectedRquestedProduct.length > 4 && (
              <div className="d-flex justify-content-end align-items-center">
                <Link
                  to={`/user/requested/product/${request}`}
                  className="btn btn-prim w-auto"
                >
                  View All
                </Link>
              </div>
            )}
            <div className="booked-container-details mt-3">
              {getSelectedRquestedProduct.length > 0 &&
                getSelectedRquestedProduct.map((item, key) => (
                  <>
                    <div className="booked-container">
                      <div className="booked-data">
                        <h5>{item.p_name}</h5>
                        <p>{item.quantity}</p>

                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "0px",
                            }}
                          >
                            <button
                              onClick={() => handleDecrement2(item._id)}
                              className="btn btn-danger btn-decrement"
                              style={{ marginRight: "10px" }}
                            >
                              <i className="fa fa-minus" aria-hidden="true"></i>
                            </button>
                            <p className="product-counter">
                              {productQuantities1[item._id]
                                ? productQuantities1[item._id]
                                : item.quantity}
                            </p>
                            <button
                              onClick={() =>
                                handleIncrement2(item._id, item.quantity)
                              }
                              className="btn btn-primary btn-increment"
                              style={{ marginLeft: "10px" }}
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            orderRquestedProduct(item._id, item.quantity)
                          }
                          className="btn btn-prim "
                        >
                          Order
                        </button>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            {requestedSelectedNotFound && (
              <div className="row ">
                <div className="col-12">
                  <p className="text-center text-danger">No data found.</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {role.toLowerCase() !== "site user" &&
        role.toLowerCase() !== "super admin" && (
          <div className="container-wrap m-3">
            <div className="container-text">
              <h5>Access Request</h5>
              <p className="description-text">
                List the access request by the users
              </p>

              <div className="dashboard-table-view">
                <div className={styles.tables_wrap}>
                  <div className="table-responsive">
                    <table
                      cellPadding="10px"
                      className="table align-middle dashboard-table"
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th>No.</th>
                          <th>Requested</th>
                          <th>Datetime</th>
                          <th>Status</th>
                          <th>OTP</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading4 && (
                          <>
                            <tr>
                              <td
                                colSpan={6}
                                className="text-center"
                                style={{
                                  background: "transparent",
                                }}
                              >
                                <HashLoader
                                  color={`#0b0d41`}
                                  loading={isLoading4}
                                  size={40}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </td>
                            </tr>
                          </>
                        )}

                        {getUsersRequests.length > 0 &&
                          getUsersRequests.map((rowData, key) => (
                            <tr
                              key={rowData._id}
                              className="row-data"
                              style={{ marginBottom: "10px" }}
                            >
                              <td></td>
                              <td>{key + 1}</td>
                              <td className="text-capitalize">
                                {rowData.message}
                              </td>
                              <td>{formattedDate(rowData.createdAt)}</td>

                              <td>
                                <span
                                  className={
                                    rowData.status === "pending"
                                      ? "status danger"
                                      : "status success"
                                  }
                                >
                                  {rowData.status}
                                </span>
                              </td>
                              <td className="text-capitalize">{rowData.pin}</td>
                              <td className="text-center">
                                {rowData.status == "pending" && (
                                  <button
                                    className="btn btn-prim w-auto mb-2"
                                    onClick={() => handleCode(rowData._id)}
                                    disabled={
                                      getUsersRequests[rowData._id] && isLoading
                                    }
                                  >
                                    <i
                                      className="fa fa-spinner fa-spin"
                                      style={iocnStyle}
                                    ></i>{" "}
                                    Send OTP
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}

                        {usersRequestsNotFound && (
                          <tr>
                            <td colSpan={6} className="text-center">
                              <p className="text-center text-danger">
                                No data found.
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      {role.toLowerCase() !== "site user" && (
        <div className="container-wrap m-3">
          <div className="container-text">
            <div className="d-flex justify-content-between flex-row ">
              <div className="d-flex justify-content-center flex-column">
                <h5>Locker History</h5>
                <p className="description-text">List of all lockers history</p>
              </div>
              <button
                className="btn btn-prim w-auto px-3"
                onClick={fetchDataAuth}
                disabled={isLoading}
              >
                <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{" "}
                {isLoading ? "Generating..." : "Generate Excel"}
              </button>
            </div>
            {getLockerHistory.length > 4 && (
              <div className=" d-flex justify-content-end align-items-center ">
                <Link
                  to={`/lockers/history/details/${getSite ? getSite : site}`}
                  className="btn btn-prim w-auto"
                >
                  View All
                </Link>
              </div>
            )}
            <div className="dashboard-table-view">
              <div className={styles.tables_wrap}>
                <div className="table-responsive">
                  <table
                    cellPadding="10px"
                    className="table align-middle dashboard-table"
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>No.</th>
                        <th>User Name</th>
                        <th>Product</th>
                        <th>Site</th>
                        <th>Locker Number</th>
                        <th>QTY</th>
                        <th>Opening Reason</th>
                        <th>Opening Date</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading3 && (
                        <>
                          <tr>
                            <td
                              colSpan={8}
                              className="text-center"
                              style={{
                                background: "transparent",
                              }}
                            >
                              <HashLoader
                                color={`#0b0d41`}
                                loading={isLoading3}
                                size={40}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </td>
                          </tr>
                        </>
                      )}
                      {isDisplay1 &&
                        getLockerHistory.map((item, key) => (
                          <tr
                            key={key}
                            className="row-data "
                            style={{ marginBottom: "10px" }}
                          >
                            <td></td>
                            <td>{key + 1}</td> {/* Display the index number */}
                            <td className="text-capitalize">
                              {item.user_name}
                            </td>
                            <td className="text-capitalize">{item.name}</td>
                            <td className="text-capitalize">{item.s_name}</td>
                            <td className="text-capitalize">{item.locker}</td>
                            <td>{item.quantity}</td>
                            <td>{item.reason}</td>
                            <td>{formattedDate(item.date)}</td>
                            <td></td>
                          </tr>
                        ))}
                      {lockerHistoryNotFound && (
                        <tr>
                          <td colSpan={8} className="text-center">
                            <p className="text-center text-danger">
                              No data found.
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* </>
      )} */}
      {isAuthenticated.role.toLowerCase() == "site usersss" && (
        <>
          <div className="row mt-4">
            <div className="col-md-8">
              <div className="row">
                {/* col-3 */}
                <div className="col-lg-3 col-sm-6">
                  <div className={styles.card_wrap}>
                    <div className={styles.card_cont}>
                      <p className={styles.card_text}>
                        Occupied <br />
                        Lockers
                      </p>
                      <h3 className={styles.card_head}>
                        {lockerCount?.occupied}
                      </h3>
                    </div>
                    <div className={styles.card_icon}>
                      <img src="" alt="" />
                    </div>
                  </div>
                </div>
                {/* col-3 */}
                <div className="col-lg-3 col-sm-6">
                  <div className={styles.card_wrap}>
                    <div className={styles.card_cont}>
                      <p className={styles.card_text}>
                        Available <br /> Lockers
                      </p>
                      <h3 className={styles.card_head}>
                        {lockerCount?.available}
                      </h3>
                    </div>
                    <div className={styles.card_icon}>
                      <img src="" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className={styles.card_wrap}>
                    <div className={styles.card_cont}>
                      <p className={styles.card_text}>
                        Inactive <br /> Lockers
                      </p>
                      <h3 className={styles.card_head}>
                        {lockerCount?.inactive}
                      </h3>
                    </div>
                    <div className={styles.card_icon}>
                      <img src="" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className={styles.card_wrap}>
                    <div className={styles.card_cont}>
                      <p className={styles.card_text}>
                        Total <br />
                        Locker
                      </p>
                      <h3 className={styles.card_head}>{lockerCount?.total}</h3>
                    </div>
                    <div className={styles.card_icon}>
                      <img src="" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4">
                          <img src={qrcodeImage} alt="qrimeg" id="qr-codepic" />
                        </div> */}
          </div>
          <div className="container-wrap mt-4">
            <div className="container-text">
              <h5>Container Added</h5>
            </div>
            {bookingLoading && (
              <div className="col-12 text-center">
                <HashLoader
                  color={`#0b0d41`}
                  loading={bookingLoading}
                  size={40}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
            {bookingDisplay && (
              <div className="dropbox-wrap">
                {bookings.map((item, i) => (
                  <div className="accordion mt-3" key={item._id}>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className={`accordion-button ${
                            isCollapsed ? "" : "active"
                          }`}
                          style={{ color: "#012267" }}
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseOne${item._id}`}
                          aria-expanded={true}
                          aria-controls={`collapseOne${item._id}`}
                        >
                          Booking #{i + 1}
                        </button>
                      </h2>
                      <div
                        id={`collapseOne${item._id}`}
                        className={`accordion-collapse collapse ${
                          isCollapsed === i ? "show" : ""
                        }`}
                        aria-labelledby={`heading${i}`}
                      >
                        <div className="accordion-body">
                          <div className="table-responsive">
                            <table className="table table-sm  table-hover mb-0 text-center">
                              <tbody>
                                <tr>
                                  <td>
                                    <h6>
                                      <b>Quantity</b>
                                    </h6>
                                  </td>
                                  <td>
                                    <h6>
                                      <b>Item</b>
                                    </h6>
                                  </td>
                                  <td>
                                    <h6>
                                      <b>User</b>
                                    </h6>
                                  </td>
                                  <td>
                                    <h6>
                                      <b>Location</b>
                                    </h6>
                                  </td>
                                </tr>
                                <tr>
                                  <td>{item.containers}</td>
                                  <td>
                                    <h6>Container</h6>
                                  </td>
                                  <td>
                                    <h6>{item.username}</h6>
                                  </td>
                                  <td>
                                    <h6>{item.sitename}</h6>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {bookingNotFound && (
              <div className="col-12 text-center">
                <p className="text-danger">No Data Found!</p>
              </div>
            )}
          </div>
          <div className="container-wrap">
            <div className="container-text mb-5">
              <h5>Lockers Statistic</h5>
            </div>
            <div className="container-fluided">
              {lockerSizes.length > 0 &&
                lockerSizes.map((item, i) => (
                  <div className="container-det">
                    <div className="container-fluid-text">
                      <h5>{item.size} Size Lockers</h5>
                      <h6>${item.hourly}/HOUR</h6>
                      <p>(Rented {item.hourly_booking} time)</p>
                      <h6>${item.daily}/DAILY</h6>
                      <p>(Rented {item.daily_booking} time)</p>
                      <h6>${item.monthly}/MONTHLY</h6>
                      <p>(Rented {item.monthly_booking} time)</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
      <Modal
        isOpen={isModalOpen}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-3 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-4 w-sm-50 h-sm-50 col-12 rounded-3"
          style={{ background: "#fff" }}
        >
          <div className="d-flex justify-content-end align-items-center flex-row">
            <img
              src={CrossIcon}
              alt="cross"
              onClick={() => setIsModalOpen(false)}
            />
          </div>

          <div
            className="p-2 rounded-2 w-sm-25  " //h-75
            style={{ background: "#fff" }}
          >
            <form onSubmit={formSubmit}>
              <div className="d-flex flex-column">
                <div>
                  <label
                    htmlFor="name"
                    className="form-label"
                    style={{ fontSize: "14px", fontWeight: "500" }}
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="fuserName"
                    name="name"
                    placeholder="Enter Name.."
                    value={credentialsModal.name}
                    onChange={handleInput3}
                  />
                </div>

                <div>
                  <label
                    htmlFor="number"
                    className="form-label"
                    style={{ fontSize: "14px", fontWeight: "400" }}
                  >
                    Quantity
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="quantity"
                    name="quantity"
                    placeholder="Enter Quantity.."
                    value={credentialsModal.quantity}
                    onChange={handleInput3}
                  />
                </div>
                <div>
                  <label
                    htmlFor="number"
                    className="form-label"
                    style={{ fontSize: "14px", fontWeight: "400" }}
                  >
                    Price
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="price"
                    name="price"
                    placeholder="Enter Price.."
                    value={credentialsModal.price}
                    onChange={handleInput3}
                  />
                </div>
                <div>
                  <label
                    htmlFor="number"
                    className="form-label"
                    style={{ fontSize: "14px", fontWeight: "400" }}
                  >
                    Image
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    onChange={handleFileSelect}
                  />
                </div>
                {file && (
                  <div className="upld-img-wrap">
                    <img width={50} src={URL.createObjectURL(file)} alt="" />
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-end align-items-center my-3">
                <button
                  type="submit"
                  disabled={isLoading}
                  href=""
                  className="btn btn-prim w-auto ms-5"
                >
                  <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{" "}
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isModalOpen2}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-3 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div
          className="p-4 w-sm-50 h-sm-50 col-10 rounded-3"
          style={{ background: "#fff" }}
        >
          <div className="d-flex justify-content-end align-items-center flex-row">
            <img
              src={CrossIcon}
              alt="cross"
              onClick={() => setIsModalOpen2(false)}
            />
          </div>

          <div
            className="p-2 rounded-2 w-sm-25  " //h-75
            style={{ background: "#fff" }}
          >
            <form onSubmit={handleSubmitPrice}>
              <div className="d-flex flex-column">
                <div>
                  <label
                    htmlFor="price"
                    className="form-label"
                    style={{ fontSize: "14px", fontWeight: "500" }}
                  >
                    Price
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="price"
                    name="price"
                    placeholder="Enter Price.."
                    onChange={handleChangeInput}
                    value={isPrice}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center my-3">
                <button
                  type="submit"
                  disabled={isLoading}
                  href=""
                  className="btn btn-prim w-auto ms-5"
                >
                  <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{" "}
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Dashboard;
