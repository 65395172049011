import React, { useEffect, useCallback, useRef, useState } from "react";
import Modal from "./Modal";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Authentication";
import io from "socket.io-client";
import { useSelector } from "react-redux";
import DateDisplay from "../template/date/DateDisplay";
import NotificationIcon from "../../assets/icons/icon-notifications.svg";
import ProfileImg from "../../images/profile.png";
import DeclineIcon from "../../images/decline-call.png";
import Cross from "../../assets/new-design/cross_icon.svg";
import PinIcon from "../../assets/new-design/Pin-icon.png";
import AttendVideoIcon from "../../images/attend-video.png";
import Scanner from "../../assets/new-design/qr-scanner.png";
import HamburgerIcon from "../../assets/icons/icon-menubar.svg";
import ringTone from "../../assets/ringcall.mp3";
import { notification } from "antd";
// const url = process.env.REACT_APP_APP_BACK_URL;
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);
// import { useSocket } from "../templates/Sockets/SocketProvider";
const Header = (props) => {
  // const url = process.env.REACT_APP_APP_BACK_URL;
  let navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const ringCall = useRef(null);
  const [room, setRoom] = useState(null);
  const [sender, setSender] = useState(null);
  const [getNotification, setNotification] = useState([]);
  const [result, setResult] = useState("");
  const [backgroundAudio] = useState(new Audio(ringTone));
  const dispatch = useDispatch();
  const [msg, setMsg] = "";
  const [qrScanModal, setQrScanModal] = useState(false);
  const isAuthenticated = useSelector((state) => state.user);
  const [qrCode, setQrCode] = useState(isAuthenticated.qr_image);
  // const imageUrl = isAuthenticated.image;
  const [notificationData, setNotificationData] = useState([]);
  const [acessPermission, setAcessPermission] = useState(false);
  const [enterOTP, setEnterOTP] = useState(false);
  const [qrCodeTimestamp, setQrCodeTimestamp] = useState(Date.now());
  useEffect(() => {
    socket.emit("login", isAuthenticated._id);
    socket.on("notification", (data) => {
      if (ringCall.current) {
        ringCall.current.click();
      }
      setSender(data.senderId);
      setIsOpen(true);
    });

    socket.on("messageNotification", (data) => {
      SmsNotificationList();
    });
    SmsNotificationList();
  }, [isAuthenticated]);
  let subtitle;

  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }
  function closeModal() {
    setIsOpen(false);
  }
  // function joinRoom() {
  //   setIsOpen(false);
  //   navigate(`/video/call/${sender}/?admin=true`);
  // }
  function joinRoom() {
    backgroundAudio.pause();
    // Pause the video playback

    setIsOpen(false);
    navigate(`/room/${sender}/?remote=true`);
  }
  const ring = async () => {
    backgroundAudio.play();
  };
  const handleLogout = async (e) => {
    const id = localStorage.getItem("token");
    const logouts = await localStorage.removeItem("token");
    dispatch(logout());
    navigate("/");
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("auth-token", id);
    const response = await fetch(
      `${url}/api/auth/user/update/chatStatusOffline`,
      {
        mode: "cors",
        method: "GET",
        headers: headers,
      }
    );
  };
  const SmsNotificationList = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/chat/notifications/${isAuthenticated._id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      setNotificationData(json.notifications);
    } catch (error) {
      console.error(error);
    }
  };

  const notificationRead = async (notificationId) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/chat/notification/read/${notificationId}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      SmsNotificationList();
      // if (json.success) {
      //   const queryParam = `user=${json.id}`;
      //   navigate(`/chats?${queryParam}`);
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchNewQRCode = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/auth/update-user-qr/${isAuthenticated._id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.success) {
        setQrCodeTimestamp(Date.now());
      }
    } catch (error) {
      // notification.error({
      //   message: "Failed",
      //   description: error.message,
      //   duration: 3,
      // });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Fetch the new QR code here
      fetchNewQRCode();
    }, 10000); // Update every 5 seconds

    return () => {
      clearInterval(intervalId); // Cleanup interval on unmount
    };
  }, []);
  const handleQrCodeScan = () => {
    setQrScanModal(true);
  };

  const notifcationData1 = [
    {
      title: "Locker OTP",
      message: "1234: Your OTP to access locker #3",
      createdAt: "12 Mar , 2024",
    },
    {
      title: "Site OTP",
      message: "4321: Your OTP to access site Invid",
      createdAt: "12 Mar , 2024",
    },
  ];

  const focusNext = (current) => {
    const maxLength = parseInt(current.getAttribute("maxlength"));
    const currentLength = current.value.length;

    if (currentLength >= maxLength) {
      const next = current.nextElementSibling;
      if (next && next.tagName === "INPUT") {
        next.focus();
      }
    }
  };

  return (
    <div>
      <header>
        <div className="row">
          <div className="col-lg-6">
            <div className="wel-not text-capitalize d-flex justify-content-start flex-row">
              {isAuthenticated.qr_image && (
                <div onClick={handleQrCodeScan}>
                  <img
                    src={`/${qrCode}?t=${qrCodeTimestamp}`}
                    style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "5px",
                      marginBottom: "3px",
                      marginRight: "10px",
                    }}
                  />
                </div>
              )}
              <h1>Welcome Back {isAuthenticated.name}!</h1>
            </div>
          </div>
          <div className="col-lg-6 text-end">
            <div className="prof-sett">
              <div className="mob_btn_hambrgr" onClick={props.toggleActive}>
                <img src={HamburgerIcon} alt="" />
              </div>
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  id="dropdownNot"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    position: "relative",
                    ...(notificationData.length > 0 && {
                      paddingRight: "5px", // Adjust the padding to accommodate the indicator
                    }),
                  }}
                >
                  <img src={NotificationIcon} alt="" />
                  {notificationData.length > 0 && (
                    <span
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        width: "7px",
                        height: "7px",
                        backgroundColor: "red",
                        borderRadius: "50%",
                      }}
                    ></span>
                  )}
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownNot">
                  <li className="notification_heads">
                    <div className="not_head_wrap">
                      <h3 className="not_head">Notifications</h3>
                    </div>
                    <div className="not_num">
                      <span className="not_num_icon">
                        {notificationData.length}
                      </span>
                    </div>
                  </li>
                  {notificationData &&
                    notificationData.map((notification) => (
                      <li
                        className="dropdown-item"
                        onClick={() => notificationRead(notification._id)}
                        key={notification._id}
                      >
                        <ul className="not_ul">
                          <li className="not_list">
                            <div className="not_prof_wrap">
                              <img
                                className="not_prof_img"
                                src={ProfileImg}
                                alt=""
                              />
                            </div>
                            <div className="not_text_wrap">
                              <p className="not_cont_text">
                                <b>{notification.title}</b>
                                <br /> {notification.message}
                                <br />
                                <DateDisplay date={notification.createdAt} />
                              </p>
                            </div>
                            {/* <div className="not_time_wrap">
                              <span className="not_time_text">
                              <DateDisplay date={notification.createdAt} />
                              </span>
                            </div> */}
                          </li>
                        </ul>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="prof-wrap">
                <div className="det-prof">
                  <div className="name-wrap">
                    <h5 className="mb-0 text-capitalize">
                      {isAuthenticated.name}
                    </h5>
                    <p>{isAuthenticated.email}</p>
                  </div>
                </div>
                <div className="prof-img">
                  {isAuthenticated.image ? (
                    <Link to="/profile">
                      <div className="img-profile-wrap">
                        <img
                          src={isAuthenticated.image}
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "5px",
                            marginBottom: "10px",
                          }}
                        />
                      </div>
                    </Link>
                  ) : (
                    <Link to="/profile">
                      <img src={ProfileImg} alt="" />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <a ref={ringCall} onClick={ring}></a>
      <Modal isOpen={modalIsOpen} onClose={closeModal}>
        <div className="modal-body">
          <div className="row h-100">
            <div className="col-12 mt-auto">
              <div className="incoming-call-sec">
                <h3 className="text-center">Inviting you for a video chat</h3>
                <div className="call-detail-bar">
                  <div className="call-options-wrap">
                    <div className="icons-wrap-options">
                      <div className="icon-dec">
                        <img src={DeclineIcon} alt="" onClick={closeModal} />
                      </div>

                      <div className="icon-att-vid" onClick={joinRoom}>
                        <img src={AttendVideoIcon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={qrScanModal}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Set the overlay background color
          },
        }}
      >
        <div className="d-flex justify-content-end align-items-center">
          <img
            src={Cross}
            alt="cross"
            onClick={() => setQrScanModal(false)}
            width={28}
          />
        </div>
        <div className="p-4 w-sm-50 h-sm-50 col-12 rounded-3">
          <div className="qrcode-content-wrap">
            <div className=" d-flex justify-content-center flex-column gap-5">
              <img src={`/${qrCode}?t=${qrCodeTimestamp}`} />
            </div>
            <div className="scanner-buttons d-flex justify-content-between flex-row gap-3 mt-3">
              <a
                href={`/${qrCode}?t=${qrCodeTimestamp}`}
                download="qr_code.png"
                className="btn btn-prim-scan btn-transparent-scan"
                rel="noreferrer"
                target="_blank"
              >
                Download
              </a>
              <button className="btn btn-prim-scan px-5">Share</button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={acessPermission}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <div className="d-flex justify-content-end align-items-center">
          <img
            src={Cross}
            alt="cross"
            onClick={() => setAcessPermission(false)}
            width={28}
          />
        </div>
        <div className="p-4 w-sm-50 h-sm-50 col-12 rounded-3">
          <div className="d-flex justify-content-center flex-column gap-3">
            <h2>Access Denied</h2>
            <p
              style={{
                fontSize: "12px",
                color: "#999999",
              }}
            >
              You don’t have permission to access this locker. You can request
              for permission and an admin will review it.
            </p>
            <div className="d-flex justify-content-center flex-row gap-2">
              <button className="btn btn-prim btn-transparent">Cancel</button>
              <button className="btn btn-prim ">Request Permission</button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={enterOTP}
        contentLabel="Example Modal"
        className="d-flex flex-column justify-content-center p-3 col-lg-4 m-auto mt-5 h-100 align-items-center "
        shouldCloseOnOverlayClick={false}
        style={{
          content: {
            fontFamily: "Arial",
            fontWeight: "800",
            fontSize: "30px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <img src={PinIcon} alt="pin" width={28} />
          <img
            src={Cross}
            alt="cross"
            onClick={() => setEnterOTP(false)}
            width={28}
          />
        </div>
        <div className="p-4 w-sm-50 h-sm-50 col-12 rounded-3">
          <div>
            <h2>Enter Your OTP</h2>
            <p
              style={{
                fontSize: "12px",
                color: "#999999",
              }}
            >
              Enter one time pin you have received via message
            </p>

            <div className="otp-input-container">
              <input
                type="text"
                className="otp-input"
                maxLength="1"
                onKeyUp={(e) => focusNext(e.target)}
              />
              <input
                type="text"
                className="otp-input"
                maxLength="1"
                onKeyUp={(e) => focusNext(e.target)}
              />
              <input
                type="text"
                className="otp-input"
                maxLength="1"
                onKeyUp={(e) => focusNext(e.target)}
              />
              <input
                type="text"
                className="otp-input"
                maxLength="1"
                onKeyUp={(e) => focusNext(e.target)}
              />
            </div>

            <div className="d-flex justify-content-center flex-row gap-2">
              <button className="btn btn-prim btn-transparent">Resend</button>
              <button className="btn btn-prim ">Submit</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Header;
