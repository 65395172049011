import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { Amplify } from "aws-amplify";
import { awsExports } from "./aws-exports";
// import { Authenticator } from '@aws-amplify/ui-react';

import store from "./store";

// Configure Amplify
Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
    oauth: {
      domain: "ivmlok3r.auth.us-east-1.amazoncognito.com",
      redirectSignIn: "https://vending.invidtech.com/aws-login", // Make sure this URI is correct
      redirectSignOut: "https://vending.invidtech.com", // Adjust this if needed
      responseType: "code",
      scope: ["email", "openid", "profile"],
    },
  },
});

// Initialize Redux persisted state
const persistedState = localStorage.getItem("reduxState")
  ? JSON.parse(localStorage.getItem("reduxState"))
  : {};
store.dispatch({ type: "SET_INITIAL_STATE", payload: persistedState });

// Render the app
ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <App />
  </Provider>
);
