import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../Redux/Authentication";
import { Authenticator } from "@aws-amplify/ui-react";
import { Auth, Hub } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import logo_site from "../../assets/logo_black.png";
import styles from "./style.module.css";
import { notification } from "antd";

const NewLogin = () => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Handle authentication state changes
    const listener = (data) => {
      const { event } = data.payload;
      if (event === "signIn") {
        fetchUserData();
      } else if (event === "signOut") {
        setUserData(null);
        setIsLoading(true);
      }
    };

    Hub.listen("auth", listener);

    // Clean up the listener on component unmount
    return () => Hub.remove("auth", listener);
  }, []);

  const fetchUserData = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setUserData(currentUser);
      formSubmit(currentUser.signInUserSession.idToken.payload.email);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setIsLoading(false); // Ensure loading is false even if an error occurs
    }
  };

  const formSubmit = async (email) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");

      const response = await fetch(
        `${process.env.REACT_APP_APP_BACK_URL}/api/auth/get-awsuser/${email}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();

      if (json.success) {
        const user = json.user;
        const expirationTime = Math.floor(Date.now() / 1000) + 7200;
        localStorage.setItem("token", json.authToken);
        localStorage.setItem("token-expiration", expirationTime);

        // Store AWS tokens
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();
        const idToken = session.getIdToken().getJwtToken();
        localStorage.setItem("awstoken", accessToken);
        localStorage.setItem("awstokenId", idToken);

        dispatch(loginSuccess(user)); // Dispatch Redux action to save user data

        // Redirect based on user role
        if (user.role.toLowerCase() === "tenant") {
          navigate("/tenant/appointment/list");
        } else if (user.role.toLowerCase() === "user") {
          const booking = localStorage.getItem("booking");
          localStorage.removeItem("booking");
          if (booking) {
            navigate(`/update/drop-off/${booking}`);
          } else {
            navigate(`/booking/add`);
          }
        } else {
          navigate("/dashboard");
        }
      } else {
        // Sign out from AWS Cognito and wait until it's done
        await Auth.signOut();
        notification.error({
          message: "Failed",
          description: json.error,
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        message: "Failed",
        description: error.message,
        duration: 3,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.heading}>
      <section className={styles.sign_sec}>
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 my-auto">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-8 p-0 text-center mob-1">
                  <div className={styles.cont_form_wrap}>
                    <div className={styles.signup_wrap}>
                      <div className={styles.logo_wrap}>
                        <img
                          src={logo_site}
                          alt="logo"
                    
                          width={200}
                        />
                      </div>
                      <h1>Welcome Back</h1>
                      <Authenticator
                        socialProviders={[
                          "google",
                          "facebook",
                          "amazon",
                          "apple",
                        ]}
                        initialState="signIn"
                        hideSignUp={true}
                      >
                        {({ signOut, user }) => (
                          <div>
                            {isLoading ? (
                              <h1>Loading user data...</h1>
                            ) : (
                              userData && (
                                <div>
                                  <h1>Welcome, {userData.attributes.email}!</h1>
                                  <button onClick={signOut}>Sign Out</button>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </Authenticator>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NewLogin;
